<template>
  <div class="container-fluid p-4">
    <h1>Reports</h1>
    <div class="d-flex flex-column pt-4">
      <div v-for="(reportType, index) in reportTypes" class="mt-2" :key="index">
        <router-link
          :to="{ name: reportType.path_name }"
          v-slot="{ href, route, navigate }"
          custom
        >
          <b-card
            @click="navigate"
            v-if="
              reportType.sc_mode.includes(getCurrentScMode) ||
              reportType.sc_mode.length === 0
            "
            :title="reportType.title"
            class="bg-surface-2"
          >
            <b-card-text>
              {{ reportType.description }}
            </b-card-text>
          </b-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      reportTypes: [
        {
          title: "Report On Demand",
          description:
            "SmartLPR report that is not scheduled, but created on an as-needed basis which can cater most of the company policy.",
          sc_mode: ["SC_DASHBOARD", "SC_LPR"],
          path_name: "/reports/lpr/report-on-demand"
        },
        {
          title: "Report On Demand",
          description:
            "SmartVMS report that is not scheduled, but created on an as-needed basis which can cater most of the company policy.",
          sc_mode: ["SC_DASHBOARD", "SC_VMS"],
          path_name: "/reports/vms/report-on-demand"
        },
        {
          title: "Evacuation Report",
          description:
            "An evacuation report to include the list of checked-in visitors who are still in the premises(Not checked-out yet), that allow operator/admin to quickly provide the necessary information to emergency services makes search and rescue easier for them.",
          sc_mode: ["SC_DASHBOARD", "SC_VMS"],
          path_name: "/reports/vms/evacuation-report"
        },
        {
          title: "Scheduled Report",
          description:
            "Daily Report to minimally include the information below, which can cater most of the company policy.",
          sc_mode: ["SC_DASHBOARD", "SC_VMS"],
          path_name: "/reports/vms/scheduled-report"
        },
        {
          title: "Bulk Import Progress Report",
          description:
            "Bulk Import Progress Report is a page showing all import load files and display the statuses of the load file import. This report also lists any warning triggered during the import process.",
          sc_mode: ["SC_DASHBOARD", "SC_VMS"],
          path_name: "/reports/vms/bulk-import-progress-report"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCurrentScMode: "session/getScMode"
    })
  },
  mounted() {}
};
</script>
